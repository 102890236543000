import { Box, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { HSL, hsl2hex } from 'utils';

type ColorSliderInputProps = {
  label: string;
  domain: [min: number, max: number, stepsize: number];
  hsl: HSL;
  colorKeys: [string, string, string];
  showLabel: boolean;
} & (
  | {
      dimension: 'hue' | 'saturation' | 'lightness';
      onChange: (hsl: HSL) => void;
    }
  | {
      dimension?: undefined;
      value: number;
      onChange: (value: number) => void;
    }
);

export function ColorSliderInput(props: ColorSliderInputProps) {
  const index = useMemo<number>(() => ['hue', 'saturation', 'lightness'].findIndex((d) => d === props.dimension), [props.dimension]);
  const onChange = React.useCallback(
    (value: number) => {
      if (index === -1) {
        props.onChange(value as any);
      } else {
        const newHSL = [...props.hsl] as HSL;
        newHSL[index] = Math.min(props.domain[1] - props.domain[2] - 1, Math.max(props.domain[0], value));
        props.onChange(newHSL as any);
      }
    },
    [index, props.hsl, props.domain],
  );

  return (
    <Box>
      {props.showLabel && <Text mb={3}>{props.label}</Text>}
      <Slider
        aria-label={`slider-${props.label}`}
        defaultValue={30}
        value={!!props.dimension ? props.hsl[index] : props.value}
        min={props.domain[0]}
        max={props.domain[1]}
        step={props.domain[2]}
        onChange={onChange}
      >
        <SliderTrack
          h='1.2rem'
          borderRadius='full'
          background={`linear-gradient(to right${props.colorKeys.reduce((string, key) => `${string}, ${key}`, '')})`}
        >
          <SliderFilledTrack opacity='0' />
        </SliderTrack>
        <SliderThumb
          h='2.9rem'
          w='1.5rem'
          boxShadow='0 1px 3px 0 rgba(0,0,0,.55)'
          border='3px solid #fff'
          backgroundColor={hsl2hex(props.hsl)}
        />
      </Slider>
    </Box>
  );
}
