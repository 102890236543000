import { Grid, GridItem, Image } from '@chakra-ui/react';
import { useSession } from '@services/session';
import { LayoutWrapper, Viewer } from 'components';
import React from 'react';
import { useMediaQuery, useResizeObserver } from 'utils';
import { DependSession } from '..';
import { Depend_ModelMap } from '../../@data/models';
import { breakpoints } from '../../@theme/style';

const logo = require('assets/media/lilly-nails-logo.png');

export default function ARLayout(props: { children: React.ReactElement; shade: string }) {
  const { session, setError } = useSession<DependSession>();

  const isLarge = useMediaQuery(breakpoints.web);
  const [appWrapper, appWrapperDimensions] = useResizeObserver();
  const [app, appDimensions] = useResizeObserver();
  const [scale, setScale] = React.useState(1);

  let _shade = session.get('cc');
  if (_shade === undefined) _shade = props.shade;

  React.useEffect(() => {
    if (!appWrapperDimensions?.height || !appDimensions?.height) return;
    if (appWrapperDimensions.height >= appDimensions.height || !isLarge) setScale(1);
    else setScale(appWrapperDimensions?.height / appDimensions?.height);
  }, [isLarge, appWrapperDimensions, appDimensions, setScale]);

  return (
    <LayoutWrapper
      ref={appWrapper}
      style={{
        background:
          'linear-gradient(0deg, rgba(217,104,104,0.2) 0%, rgba(231,156,156,0.15) 15%, rgba(245,208,208,0.10) 35%, rgba(255,255,255,0) 50%)',
      }}
    >
      <Grid
        ref={app}
        transform={`scale(${scale.toFixed(2)})`}
        templateAreas={`"viewer body" "viewer nav"`}
        templateColumns={{
          base: '1fr 1fr',
          web: '1fr 1fr',
        }}
        templateRows={{
          base: '1fr max-content',
          web: '1fr max-content',
        }}
        gap='0 5vw'
        height={{
          base: appWrapperDimensions?.height,
          web: 'unset',
        }}
        minH={'100%'}
        flex='1'
      >
        <GridItem area='viewer' alignSelf={'stretch'} position={'relative'}>
          <Viewer
            key={'viewer'}
            blendMode='multiply'
            models={Depend_ModelMap}
            color={_shade}
            onError={(message?: string) => {
              setError(message || 'The AI viewer crashed for an unknown reason. Please try again.');
            }}
          />
          <Image
            src={logo}
            alt='Lilly Nails'
            style={{
              position: 'absolute',
              left: '2%',
              bottom: '2%',
              width: '16%',
              filter: 'invert(1)',
            }}
          />
        </GridItem>
        {props.children}
      </Grid>
    </LayoutWrapper>
  );
}
