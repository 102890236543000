import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/libre-caslon-text';
import '@fontsource/work-sans';
import { AuthProvider, useAuth } from '@services/authentication';
import { initializeApp } from 'firebase/app';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Theme from './@theme/style';
import DependApplet from './depend';
import EllureApplet from './ellure';

initializeApp({
  apiKey: 'AIzaSyAPesAxKHfwG9yWONi4BvZ0scCASMFUyRI',
  authDomain: 'strong-skyline-360612.firebaseapp.com',
  projectId: 'strong-skyline-360612',
  storageBucket: 'strong-skyline-360612.appspot.com',
  messagingSenderId: '837864713928',
  appId: '1:837864713928:web:596e4629424ae84ac0fffd',
});

function App() {
  const { session } = useAuth();

  if (!session.user) return <div>User undefined.</div>;

  let routes = (
    <>
      <Route path='/' element={<div>Company undefined.</div>} />
    </>
  );
  switch (session.user.companyId) {
    case 'ellure':
      routes = (
        <>
          <Route path='/' element={<EllureApplet />} />
        </>
      );
      break;
    case 'depend':
      routes = (
        <>
          <Route path='/' element={<DependApplet />} />
        </>
      );
      break;
  }
  //<Button onClick={signout} >Signout</Button>

  return (
    <>
      <Router>
        <Routes>{routes}</Routes>
      </Router>
    </>
  );
}

export default function Root() {
  return (
    <ChakraProvider theme={Theme}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ChakraProvider>
  );
}
