import { Landmarks } from 'models/ar-viewer';
import { BaseApiClass } from '../base-api-class';

type GetLandmarksDto = { image: Blob; imageName: string };

export class RecommendationApi extends BaseApiClass {
  /** Retrieves landmarks based on a image of a person. */
  public readonly getLandMarks = this.createEndpoint<GetLandmarksDto, Landmarks>({
    method: 'post',
    endpoint: ['recommendations', 'detection', 'lips'],
    requestConfig: (dto) => {
      const data = new FormData();
      data.append('file', dto.image, dto.imageName);
      return {
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    },
  });
}
