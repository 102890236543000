import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { api } from '@services/api';
import { useSession } from '@services/session';
import { LayoutWrapper, Swatch } from 'components';
import React from 'react';
import { validateEmail } from 'utils';
import { EllureSession, GoToViewFunc, VIEWS } from '..';

const logo = require('assets/ellure-logo.png');

export default function EndView(props: { goToView: GoToViewFunc; shade: string }) {
  const { session, resetSession, setError } = useSession<EllureSession>();

  const [isLoading, setLoading] = React.useState(false);
  const [isSubmitted, setSubmitted] = React.useState(false);

  const [name, setName] = React.useState({ touched: false, isValid: false, value: '' });
  const [email, setEmail] = React.useState({ touched: false, isValid: false, value: '' });
  const [pin, setPin] = React.useState({ touched: false, isValid: true, value: '' });

  const shade = session.get('cc');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const submit = React.useCallback(() => {
    if (isSubmitted) return;

    if (!name.isValid) {
      setName({ touched: true, isValid: false, value: name.value });
      return;
    }

    if (!email.isValid) return;

    setLoading(true);
    api.collector
      .createProduct({
        email: email.value,
        name: name.value,
        content: [
          {
            type: 'lipstick',
            derivedFrom: {
              expectedShade: props.shade,
            },
          },
        ],
      })
      .then(() => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred while submitting your product. Please try it later again.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [name, email, isSubmitted, props.shade]);

  React.useEffect(() => {
    if (isSubmitted) setTimeout(resetSession, 10 * 1000);
  }, [isSubmitted]);

  return (
    <>
      <LayoutWrapper
        style={{
          background:
            'linear-gradient(0deg, rgba(217,104,104,0.2) 0%, rgba(231,156,156,0.15) 15%, rgba(245,208,208,0.10) 35%, rgba(255,255,255,0) 50%)',
        }}
      >
        <Grid templateAreas={`"body"`} templateColumns={'100%'} templateRows={'1fr'} gap='0 5vw' height='100%' flex='1'>
          <GridItem area='body' textAlign='center' alignSelf={'center'} justifySelf='center'>
            {isSubmitted ? (
              <>
                <Flex flexDir='column' alignItems={'center'} gap={6} maxWidth={'600px'} mb={'15em'}>
                  <Image src={logo} alt='Lilly Nails' width={'100px'} />
                  <Heading as='h1'>You are all set!</Heading>
                  <Text>Your nail artist will now start the robot to make your personal shade!</Text>
                </Flex>
              </>
            ) : (
              <>
                <Flex flexDir='column' alignItems={'center'} gap={6} maxWidth={'600px'}>
                  <Image src={logo} alt='Lilly Nails' width={'100px'} />
                  <Heading as='h1'>Almost there… we only need to know your name and email</Heading>
                  <Flex gap={10} mb={10}>
                    <Flex flexDir='column'>
                      <Swatch shade={shade || 'grey'} isChecked={!!shade} width='160px' />
                    </Flex>
                    <Stack spacing={5}>
                      <FormControl
                        maxW={'500px'}
                        w={'400px'}
                        isDisabled={isLoading || isSubmitted}
                        isRequired
                        isInvalid={name.touched && !name.isValid}
                      >
                        <FormLabel>Shade name</FormLabel>
                        <Input
                          value={name.value}
                          background={'whiteAlpha.900'}
                          onChange={(e) => setName({ touched: true, isValid: e.target.value !== '', value: e.target.value })}
                        />
                        <FormErrorMessage>Name cannot be empty.</FormErrorMessage>
                      </FormControl>
                      <FormControl maxW={'500px'} isDisabled={isLoading || isSubmitted} isInvalid={email.touched && !email.isValid}>
                        <FormLabel>Your email</FormLabel>
                        <Input
                          value={email.value}
                          background={'whiteAlpha.900'}
                          type='email'
                          placeholder='your@email.se'
                          onChange={(e) =>
                            setEmail({
                              touched: true,
                              isValid: e.target.value === '' || !!validateEmail(e.target.value),
                              value: e.target.value,
                            })
                          }
                        />
                        <FormErrorMessage>Email is invalid.</FormErrorMessage>
                      </FormControl>
                    </Stack>
                  </Flex>
                  <Button
                    size='lg'
                    colorScheme='depend'
                    isDisabled={isLoading || isSubmitted}
                    isLoading={isLoading}
                    onClick={() => {
                      if (!name.isValid) {
                        setName({ touched: true, isValid: false, value: name.value });
                        return;
                      }

                      if (!email.isValid) {
                        setEmail({ touched: true, isValid: false, value: email.value });
                        return;
                      }

                      onOpen();
                    }}
                  >
                    Submit shade
                  </Button>
                </Flex>
                <Divider my={6} />
                <Button
                  size='lg'
                  background={'whiteAlpha.600'}
                  variant={'outline'}
                  isDisabled={isLoading || isSubmitted}
                  onClick={props.goToView.bind(null, VIEWS.CONFIRM)}
                >
                  Back
                </Button>
              </>
            )}
          </GridItem>
        </Grid>
      </LayoutWrapper>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} scrollBehavior='inside' motionPreset='scale'>
        <ModalOverlay />
        <ModalContent maxW={{ base: 'auto', web: '600px' }}>
          <ModalHeader>Confirm production</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Please contact in-store personel to proceed.</Text>
            <HStack justifyContent={'center'} py={6}>
              <PinInput
                mask
                isInvalid={!pin.isValid}
                value={pin.value}
                onChange={(v) =>
                  setPin({
                    touched: true,
                    isValid: true,
                    value: v,
                  })
                }
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </ModalBody>

          <ModalFooter pt={0}>
            <ButtonGroup>
              <Button
                colorScheme='blue'
                onClick={() => {
                  if (pin.value !== '1234') {
                    setPin({ touched: true, isValid: false, value: pin.value });
                    return;
                  }

                  onClose();
                  submit();
                }}
              >
                Proceed
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
