import { httpAgent } from '@services/api';

export enum AuthFlowType {
  SIGNIN = 'signin',
  COMPLETEPASSWORD = 'complete-password',
  CHANGEPASSWORD = 'change-password',
  RESETPASSWORD = 'reset-password',
  AUTHENTICATED = 'authenticated',
}

export enum AuthErrorMessages {
  TOKEN_ERROR = 'Authentication could not be completed, please contact an administrator.',
  USER_RETRIEVAL_ERROR = 'User data could not be retrieved correctly, please contact an administrator.',
  INVALID_INPUT = 'The form contains errors.',
  INVALID_CREDENTIALS = 'Username/password does not match, please try again.',
}

export type SignupConfig = {
  username: string;
  password: string;
};

export type AuthState = {
  signup: (config: SignupConfig) => Promise<void>;
  signin: (username: string, password: string) => Promise<void>;
  signout: () => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  refresh: (forceRefresh: boolean) => void;
  error?: string;
  session: SessionData;
};

export type SessionData = {
  token: string | null;
  user?: {
    companyId: string | null;
    userId: string;
  };
  location?: {
    locationId: string | null;
    locationName: string;
  };
};

export const defaultAuthData: SessionData = {
  token: null,
};

export type AuthDataUpdateEvents = {
  SIGNIN_COMPLETED: { token: string; companyId: string | null; userId: string };
  SIGNOUT_COMPLETED: null;
  LOCATION_ASSIGNED: { locationId: string | null; locationName: string };
};

export type Action<Actions extends Record<string, any>> = {
  [K in keyof Actions]: {
    type: K;
    payload: Actions[K];
  };
}[keyof Actions];

export function authDataReducer(state: SessionData, action: Action<AuthDataUpdateEvents>) {
  const { type, payload } = action;
  switch (type) {
    case 'SIGNIN_COMPLETED':
      httpAgent.defaults.headers.common['x-api-key'] = payload.token;
      return {
        ...state,
        token: payload.token,
        user: {
          companyId: payload.companyId,
          userId: payload.userId,
        },
      };
    case 'SIGNOUT_COMPLETED':
      delete httpAgent.defaults.headers.common['x-api-key'];
      return defaultAuthData;
    case 'LOCATION_ASSIGNED':
      return {
        ...state,
        location: {
          locationId: payload.locationId,
          locationName: payload.locationName,
        },
      };
    default:
      return state;
  }
}
