import ReactDOM from 'react-dom/client';

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'shopify') {
    return import('./apps/shopify');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'creators') {
    return import('./apps/shade-creators');
  } else {
    return Promise.reject(new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET));
  }
}

// Import the entry point and render it's default export
const root = ReactDOM.createRoot(document.getElementById('root'));
importBuildTarget().then(({ default: Environment }) => {
  root.render(<Environment />);
});
