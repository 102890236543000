import { useState, useCallback, useRef, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useResizeObserver(): [
  React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
  DOMRectReadOnly | null,
] {
  const [observerEntry, setObserverEntry] = useState<ResizeObserverEntry | null>(null);
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const observer = useRef<ResizeObserver | null>(null);

  const disconnect = useCallback(() => observer.current?.disconnect(), []);

  const observe = useCallback(() => {
    observer.current = new ResizeObserver(([entry]) => setObserverEntry(entry));
    if (node) observer.current.observe(node);
  }, [node]);

  useLayoutEffect(() => {
    observe();
    return () => disconnect();
  }, [disconnect, observe]);

  return [setNode, observerEntry?.contentRect || null];
}
