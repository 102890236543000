import { useRef, useEffect } from 'react';

/**
 *
 * @param func The original, non debounced function.
 * @param delay The delay (in ms) for the function to execute if function is not called again.
 * @param deps Debounce effect will only activate if dependencies in the list change.
 */

export function useDebouncedEffect(func: () => void, delay = 1000, deps: React.DependencyList) {
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timer.current = setTimeout(func, delay);

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [timer, ...deps]);
}
