import { Box, Button, ButtonGroup, Flex, GridItem, Heading, Spacer, Text, useRadioGroup } from '@chakra-ui/react';
import { useSession } from '@services/session';
import { Swatch } from 'components';
import React from 'react';
import { DependSession, GoToViewFunc, Shades, VIEWS } from '..';

export default function ChooseBaseView(props: { goToView: GoToViewFunc; base: string }) {
  const { updateSession } = useSession<DependSession>();

  const list = Shades.bases;
  const baseShadesGroup = useRadioGroup({
    name: 'base-shade',
    defaultValue: props.base || Shades.bases[0],
  });

  React.useEffect(() => {
    updateSession({
      b: baseShadesGroup.value as string,
    });
  }, [baseShadesGroup.value, updateSession]);

  return (
    <>
      <GridItem area='body' textAlign='center'>
        <Box p={3}>
          <Heading as='h1'>Design your shade</Heading>
          <Text mt={3}>Select the base color to start your search</Text>
        </Box>
        <Flex p={3} flex='1' gap='1.5rem 5%' flexWrap='wrap' alignItems='center' alignContent='center'>
          {list.map((shade, index) => (
            <Swatch key={index} shade={shade} {...baseShadesGroup.getRadioProps({ value: shade })} />
          ))}
        </Flex>
      </GridItem>
      <GridItem area='nav' textAlign='center'>
        <ButtonGroup p={3} spacing={4}>
          <Button size='lg' variant={'outline'} background={'whiteAlpha.600'} onClick={props.goToView.bind(null, VIEWS.WELCOME)}>
            Back
          </Button>
          <Spacer />
          <Button size='lg' colorScheme='depend' onClick={props.goToView.bind(null, VIEWS.CHOOSE_SHADE)}>
            Next
          </Button>
        </ButtonGroup>
      </GridItem>
    </>
  );
}
