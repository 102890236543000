import React from 'react';

import { FirebaseError } from 'firebase/app';
import * as firebase from 'firebase/auth';
import { AuthErrorMessages, AuthFlowType, AuthState, SignupConfig, authDataReducer, defaultAuthData } from './utils';

import { useEffectPostMount } from 'utils';

import { api } from '@services/api';

import ChangePassword from './change-password';
import CompletePassword from './complete-password';
import Signin from './signin';

import { BoxLoader, LayoutWrapper } from 'components';

const AuthContext = React.createContext<AuthState>(undefined!);

export function AuthProvider({ children, ...props }: { children: React.ReactNode }) {
  const [isLoading, setLoading] = React.useState(true);
  const [session, dispatch] = React.useReducer(authDataReducer, defaultAuthData);

  const tokenRefreshTimer = React.useRef<NodeJS.Timer>();
  const [authFlowType, setAuthFlowType] = React.useState<AuthFlowType>(AuthFlowType.SIGNIN);
  const [error, setError] = React.useState('');

  const user = firebase.getAuth().currentUser;

  const signup = React.useCallback(async function ({ username, password }: SignupConfig) {
    setLoading(true);
    try {
      await firebase.createUserWithEmailAndPassword(firebase.getAuth(), username, password);
    } catch (error) {
      console.log('error signing up:', error);
      throw new Error('Error signing up');
    } finally {
      setLoading(false);
    }
  }, []);

  const signin = React.useCallback(async function (email: string, password: string) {
    setLoading(true);
    try {
      await firebase.setPersistence(firebase.getAuth(), firebase.inMemoryPersistence);
      await firebase.signInWithEmailAndPassword(firebase.getAuth(), email, password);
    } catch (e) {
      console.log(e);
      if (e instanceof FirebaseError) {
        switch (e.code) {
          case 'auth/invalid-email':
          case 'auth/wrong-password':
            throw new Error(AuthErrorMessages.INVALID_CREDENTIALS);
          case 'auth/internal-error':
            throw new Error(AuthErrorMessages.INVALID_INPUT);
        }
      }
      throw new Error('Unknown error occurred. Please contact an administrator.');
    } finally {
      setLoading(false);
    }
  }, []);

  const signout = React.useCallback(async function () {
    setLoading(true);
    try {
      await firebase.getAuth().signOut();
    } catch (error) {
      console.log('error signing out: ', error);
      throw new Error('Error signing out');
    } finally {
      setLoading(false);
    }
  }, []);

  const changePassword = React.useCallback(
    async function (newPassword: string, emailOrOldPassword: string) {
      setLoading(true);
      try {
        await firebase.updatePassword(user!, newPassword);
        setAuthFlowType(AuthFlowType.AUTHENTICATED);
      } catch (e) {
        console.log('error changing password: ', e);
        throw new Error('Error changing password');
      } finally {
        setLoading(false);
      }
    },
    [user],
  );

  const refreshToken = React.useCallback(
    async function (user: firebase.User | null, forceRefresh: boolean = false) {
      if (!user) return;
      const result = await user.getIdTokenResult(forceRefresh);
      const expirationTime = new Date(result.expirationTime);
      expirationTime.setMinutes(expirationTime.getMinutes() - 5);

      if (tokenRefreshTimer.current) clearTimeout(tokenRefreshTimer.current as any);
      tokenRefreshTimer.current = setTimeout(() => {
        const user = firebase.getAuth().currentUser;
        refreshToken(user, true);
      }, expirationTime.getTime() - Date.now());

      dispatch({
        type: 'SIGNIN_COMPLETED',
        payload: {
          token: result.token,
          companyId: typeof result.claims.companyId === 'string' ? result.claims.companyId : null,
          userId: result.claims.user_id as string,
        },
      });

      if (result.claims.locationId && result.claims.locationName)
        dispatch({
          type: 'LOCATION_ASSIGNED',
          payload: {
            locationId: result.claims.locationId as string,
            locationName: result.claims.locationName as string,
          },
        });
    },
    [dispatch],
  );

  React.useEffect(() => {
    const unsubscribe = firebase.getAuth().onIdTokenChanged((user) => {
      setLoading(true);

      if (!user) {
        dispatch({ type: 'SIGNOUT_COMPLETED', payload: null });
        setAuthFlowType(AuthFlowType.SIGNIN);
        setLoading(false);
        return;
      }

      refreshToken(user)
        .catch((e) => {
          console.log('error');
          console.log(e);
          setAuthFlowType(AuthFlowType.SIGNIN);
          setError(AuthErrorMessages.TOKEN_ERROR);
        })
        .finally(() => {
          setAuthFlowType(AuthFlowType.AUTHENTICATED);
          setLoading(false);
        });
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        signup,
        signin,
        signout,
        changePassword,
        refresh: refreshToken.bind(null, user),
        error,
        session,
      }}
    >
      <BoxLoader h='100svh' isLoading={isLoading}>
        {authFlowType === AuthFlowType.AUTHENTICATED ? <AccountFlow>{children}</AccountFlow> : <AuthFlow type={authFlowType} />}
      </BoxLoader>
    </AuthContext.Provider>
  );
}

const AuthFlow = ({ type }: { type: AuthFlowType }) => {
  let AuthFrame = Signin;
  switch (type) {
    case AuthFlowType.COMPLETEPASSWORD:
      AuthFrame = CompletePassword;
      break;
    case AuthFlowType.CHANGEPASSWORD:
      AuthFrame = ChangePassword;
      break;
  }

  return (
    <LayoutWrapper>
      <AuthFrame />
    </LayoutWrapper>
  );
};

const AccountFlow = function ({ children, ...props }: { children: React.ReactNode }) {
  const { session, refresh } = useAuth();

  const [isLoading, setLoading] = React.useState(false);
  const [isReady, setReady] = React.useState(!!session.user?.companyId && !!session.location?.locationId);

  useEffectPostMount(() => {
    if (session.token) {
      if (!session.user?.companyId) {
        setLoading(true);
        api.user
          .setCompany()
          .then(() => {
            console.log('refresh');
            refresh(true);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }

      if (!session.location?.locationId) {
        setLoading(true);
        api.user
          .setLocation()
          .then(() => {
            console.log('refresh');
            refresh(true);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }

      setReady(true);
    }
  }, [refresh, session.token !== null, session.user?.companyId, session.location?.locationId]);

  return (
    <BoxLoader h='100%' isLoading={isLoading}>
      {isReady ? children : []}
    </BoxLoader>
  );
};

function useAuth(): AuthState {
  const context = React.useContext(AuthContext);
  if (context === undefined) throw new Error('useAuth must be used within a Authentication provider');
  return context;
}

export { AuthContext, useAuth };
