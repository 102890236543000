import { AxiosInstance } from 'axios';
import { Endpoint, EndpointConfig, EndpointURL } from './endpoint';

export type ApiConfig = {
  URLprefix: string;
};

export class BaseApiClass {
  constructor(
    private axios: AxiosInstance,
    private config?: Partial<ApiConfig>,
  ) {}

  private parseEndpoint(endpoint: EndpointURL, params?: Record<string, string>) {
    let uri = typeof endpoint === 'string' ? endpoint : endpoint.join('/');
    if (params) {
      uri = Object.entries(params).reduce((uri, [key, value]) => {
        return uri.replace(new RegExp(`:${key}`, 'g'), encodeURIComponent(value));
      }, uri);
    }

    if (this.config?.URLprefix) return `${this.config.URLprefix}/${uri}`;
    return `/${uri}`;
  }

  protected createEndpoint<DtoType = undefined, ReturnType = unknown, DtoRequired extends boolean = true>(
    config?: Partial<EndpointConfig<DtoType>>,
  ) {
    const requestHandler = async function (this: BaseApiClass, dto?: DtoType): Promise<ReturnType> {
      const { params, query, ...request } =
        dto && config?.requestConfig ? config.requestConfig(dto) : { params: undefined, query: undefined };

      const response = await this.axios({
        ...request,
        params: query,
        url: this.parseEndpoint(config?.endpoint || '', params),
        method: config?.method || 'get',
      });
      return response.data;
    };

    return requestHandler.bind(this) as Endpoint<DtoType, ReturnType, DtoRequired>;
  }
}
