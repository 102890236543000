import { BaseApiClass } from '../base-api-class';

type CreateProductDto = {
  type: 'nailgel' | 'nailgel-sample' | 'lipstick';
  derivedFrom: {
    expectedShade: string;
    isSample?: string;
  };
};

type CreateOrderDto = {
  name: string;
  email: string;
  content: CreateProductDto[];
};

export class OrderCollectionApi extends BaseApiClass {
  /** Commits a new order to the register. */
  public readonly createProduct = this.createEndpoint<CreateOrderDto, never>({
    method: 'post',
    endpoint: ['orders'],
    requestConfig: (dto) => ({
      data: {
        metadata: {
          name: dto.name,
          email: dto.email,
          orderDate: new Date().toJSON(),
        },
        content: dto.content,
      },
    }),
  });
}
