import { BasePallete, ColourPallete } from 'models/ar-viewer';
import { ResolvedFormulationsResponse } from 'models/formulation';
import { BaseApiClass } from '../base-api-class';

type GetBaseShadesDto = { productType: string };

type GetShadePalleteDto = {
  shadeId: string;
  productType: string;
  tintStep?: number;
  tempStep?: number;
};

type getShadeFormulationDto = {
  shades: string[];
  productType: string;
  weight: string;
  variant?: string;
};

export class FormulationApi extends BaseApiClass {
  public readonly getBaseShades = this.createEndpoint<GetBaseShadesDto, BasePallete>({
    endpoint: ['recommendation'],
    requestConfig: ({ productType }) => ({
      query: { product: productType },
    }),
  });

  public readonly getShadePallete = this.createEndpoint<GetShadePalleteDto, ColourPallete>({
    method: 'post',
    endpoint: ['recommendation', 'pallete', 'hsl'],
    requestConfig: ({ productType, ...data }) => ({
      data,
      query: { product: productType },
    }),
  });

  /** Retrieves landmarks based on a image of a person. */
  public readonly getShadeFormulation = this.createEndpoint<getShadeFormulationDto, ResolvedFormulationsResponse>({
    method: 'post',
    endpoint: ['resolve', 'formulation'],
    requestConfig: ({ shades: data, ...query }) => ({
      data,
      query: {
        product: query.productType,
        weight: query.weight,
        variant: query.variant,
      },
    }),
  });
}
