import { SessionProvider, useSession } from '@services/session';
import React from 'react';
import SeasonMap from '../@data/seasons.json';
import ARLayout from './layout/ar-viewer';
import * as Views from './views';

export enum VIEWS {
  WELCOME,
  USE_COLOR_CODE,
  CHOOSE_SHADE,
  CONFIRM,
  END,
}

export type GoToViewFunc = (view: VIEWS) => void;

export type EllureSession = {
  s: number;
  c: string;
  cc: string;
  skip: boolean;
};

function App() {
  const { session, updateSession, setError } = useSession<EllureSession>();
  const step = parseInt(session.get('s') || '0');
  let shade = session.get('c') || SeasonMap.spring.shades[0];

  const goToView: GoToViewFunc = React.useCallback(
    (view) => {
      updateSession({
        s: view.toString(),
        c: view < VIEWS.CHOOSE_SHADE ? null : undefined,
        cc: view < VIEWS.CONFIRM ? null : undefined,
        skip: view < VIEWS.CONFIRM ? null : undefined,
      });
    },
    [updateSession],
  );

  let View: React.ReactElement;
  switch (step) {
    case VIEWS.END:
      View = <Views.End shade={shade} goToView={goToView} />;
      break;
    case VIEWS.CONFIRM:
      View = (
        <ARLayout shade={shade}>
          <Views.Confirm shade={shade} goToView={goToView} />
        </ARLayout>
      );
      break;
    case VIEWS.CHOOSE_SHADE:
      View = (
        <ARLayout shade={shade}>
          <Views.ChooseShade shade={shade} goToView={goToView} />
        </ARLayout>
      );
      break;
    case VIEWS.USE_COLOR_CODE:
      View = <Views.UseCode goToView={goToView} />;
      break;
    default:
      View = <Views.Welcome goToView={goToView} />;
      break;
  }

  return View;
}

export default function Main() {
  return (
    <SessionProvider defaultState={{ s: '0' }}>
      <App />
    </SessionProvider>
  );
}
