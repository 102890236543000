import React from 'react';

const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i));
const isIos = (): boolean =>
  Boolean(userAgent.match(/iPhone|iPad|iPod|/i) || (userAgent.includes('Macintosh') && 'ontouchend' in document));
const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i));
const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile/i));
const isSSR = (): boolean => Boolean(userAgent.match(/SSR/i));

const isMobile = (): boolean => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
const isDesktop = (): boolean => Boolean(!isMobile() && !isSSR());

export enum DEVICES {
  UNKNOWN = 'unknown',
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  SSR = 'ssr',
}

export function useDeviceDetect() {
  const [state, setState] = React.useState(DEVICES.UNKNOWN);

  React.useEffect(() => {
    if (isMobile()) setState(DEVICES.MOBILE);
    else if (isDesktop()) setState(DEVICES.DESKTOP);
    else if (isSSR()) setState(DEVICES.SSR);
  }, []);

  return state;
}
