import { BaseApiClass } from '../base-api-class';

export class UserApi extends BaseApiClass {
  /** Sets the users metadata to the company it is attached too */
  public readonly setCompany = this.createEndpoint<undefined, never>({
    method: 'post',
    endpoint: ['user', 'settings', 'company'],
  });

  /** Sets the users metadata to the location it is attached too */
  public readonly setLocation = this.createEndpoint<undefined, { locationName: string }>({
    method: 'post',
    endpoint: ['user', 'settings', 'location'],
  });
}
