import { Button, Divider, Flex, Grid, GridItem, Heading, Image, Text } from '@chakra-ui/react';
import { LayoutWrapper } from 'components';
import { GoToViewFunc, VIEWS } from '..';

const image = require('assets/media/depend-hero.jpg');
const logo = require('assets/media/lilly-nails-logo.png');

export default function WelcomeView(props: { goToView: GoToViewFunc }) {
  return (
    <LayoutWrapper
      style={{
        maxHeight: '100vh',
        background:
          'linear-gradient(0deg, rgba(217,104,104,0.2) 0%, rgba(231,156,156,0.15) 15%, rgba(245,208,208,0.10) 35%, rgba(255,255,255,0) 50%)',
      }}
    >
      <Grid templateAreas={`"img body"`} templateColumns={'1fr 1fr'} templateRows={'1fr max-content'} gap='0 5vw' height='100%' flex='1'>
        <GridItem area='img' backgroundImage={image} backgroundSize={'cover'} backgroundPosition={'left bottom'} />
        <GridItem area='body' textAlign='center' alignSelf={'center'} justifySelf='center'>
          <Flex flexDir='column' alignItems={'center'} gap={6} maxWidth={'400px'}>
            <Image src={logo} alt='Lilly Nails' width={'100px'} />
            <Heading as='h1'>Custom nail gel creator</Heading>
            <Text>
              This app will guide you in creating your own - one-of-a-kind - nail gel shade. This shade will be made on the spot while your
              nails are cleaned.
            </Text>
            <Button size='lg' colorScheme='depend' onClick={props.goToView.bind(null, VIEWS.CHOOSE_BASE)}>
              Get started!
            </Button>
          </Flex>
          <Divider my={12} />
          <Button size='lg' background={'whiteAlpha.600'} variant={'outline'} onClick={props.goToView.bind(null, VIEWS.USE_COLOR_CODE)}>
            Use a color code
          </Button>
        </GridItem>
      </Grid>
    </LayoutWrapper>
  );
}
