import axios from 'axios';
import { UserApi } from './authentication-service/user.controller';
import { OrderCollectionApi } from './order-registration-service/orderCollection.controller';
import { FormulationApi } from './product-recommendation-service/formulation.controller';
import { RecommendationApi } from './product-recommendation-service/recommendation.controller';

export function buildApi(host: string) {
  const services = {
    authentication: `${host}/auth`,
    orderRegistration: `${host}/api/register`,
    productRecommendation: `${host}/public`,
    productFormulation: `${host}/api/algorithm`,
  } as const;

  const httpAgent = axios.create({
    baseURL: host,
    withCredentials: true,
    responseType: 'json',
  });

  httpAgent.defaults.headers.common['Content-Type'] = 'application/json';

  return {
    api: {
      user: new UserApi(httpAgent, { URLprefix: services.authentication }),
      collector: new OrderCollectionApi(httpAgent, { URLprefix: services.orderRegistration }),
      recommendation: new RecommendationApi(httpAgent, { URLprefix: services.productRecommendation }),
      formulation: new FormulationApi(httpAgent, { URLprefix: services.productFormulation }),
    },
    httpAgent,
  };
}

const { api, httpAgent } = buildApi((process.env.REACT_APP_API_HOST as string) || '');
export { api, httpAgent };
