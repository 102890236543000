import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedEffect } from 'utils/hooks';

export type SessionState<SessionData> = {
  session: Map<keyof SessionData, string>;
  updateSession: (update: Partial<Record<keyof SessionData, string | number | boolean | null>>) => void;
  updateShade: (hex: string) => void;
  setError: (message: string) => void;
  resetSession: () => void;
};

const SessionContext = React.createContext<SessionState<unknown>>(undefined!);

export function SessionProvider<SessionData>({ children, defaultState }: { children: React.ReactNode; defaultState: SessionData }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sessionData, setSessionData] = React.useState<Map<keyof SessionData, string>>(
    Array.from(searchParams.entries()).reduce((map: Map<keyof SessionData, string>, [key, value]: [string, string]) => {
      map.set(key as keyof SessionData, value);
      return map;
    }, new Map()),
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null!);
  const [message, setMessage] = React.useState('');

  const updateSession = React.useCallback((update: Partial<{ [key in keyof SessionData]: string | number | boolean | undefined }>) => {
    setSessionData((session) => {
      for (const [key, value] of Object.entries(update) as [keyof SessionData, any][]) {
        if (value === undefined) continue;
        else if (value === null) session.delete(key);
        else session.set(key, value.toString());
      }
      return new Map(session);
    });
  }, []);

  const updateShade = React.useCallback((hex: string) => {
    setSessionData((session) => {
      session.set('c' as keyof SessionData, hex);
      return new Map(session);
    });
  }, []);

  const setError = React.useCallback((message: string) => {
    setMessage(message);
    onOpen();
  }, []);

  const resetSession = React.useCallback(() => {
    setSessionData(new Map());
  }, [defaultState]);

  useDebouncedEffect(
    () => {
      const params = new URLSearchParams();
      for (const [key, value] of sessionData) {
        params.set(key.toString(), value);
      }
      setSearchParams(params);
    },
    250,
    [sessionData],
  );

  return (
    <SessionContext.Provider
      value={{
        session: sessionData as any,
        updateSession,
        updateShade,
        setError,
        resetSession,
      }}
    >
      <ErrorBoundary
        fallback={<div>App crashed</div>}
        onError={(e) => {
          setMessage(e.message);
          onOpen();
        }}
      >
        {children}
      </ErrorBoundary>
      <AlertDialog motionPreset='scale' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Oh no, something went wrong :(</AlertDialogHeader>
          <AlertDialogBody>{message}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </SessionContext.Provider>
  );
}

export function useSession<SessionData>(): SessionState<SessionData> {
  const context = React.useContext(SessionContext) as SessionState<SessionData>;
  if (context === undefined) throw new Error('useSession must be used within a Session provider');
  return context;
}
