import { Box, Button, Flex, Grid, GridItem, Heading, Image } from '@chakra-ui/react';
import { LayoutWrapper } from 'components';
import { GoToViewFunc, VIEWS } from '..';

const video = require('assets/media/video/ellure-video-hero.mp4');
const logo = require('assets/ellure-logo.png');

export default function WelcomeView(props: { goToView: GoToViewFunc }) {
  return (
    <LayoutWrapper
      style={{
        maxHeight: '100svh',
        background:
          'linear-gradient(0deg, rgba(217,104,104,0.2) 0%, rgba(231,156,156,0.15) 15%, rgba(245,208,208,0.10) 35%, rgba(255,255,255,0) 50%)',
      }}
    >
      <Grid templateAreas={`"body"`} templateColumns={'1fr'} templateRows={'100%'} gap='0 5vw' height='100%' flex='1'>
        <GridItem area='body' textAlign='center' alignSelf={'center'} justifySelf='center' zIndex={10}>
          <Flex flexDir='column' alignItems={'center'} gap={6} maxWidth={'400px'} mb={6}>
            <Image src={logo} alt='Ellure Lipstick' width={'200px'} mb={5} filter={'invert(1)'} />
            <Heading color={'white'} as='h1'>
              Lipstick made by you
            </Heading>
            <Button size='lg' backgroundColor={'white'} color={'black'} onClick={props.goToView.bind(null, VIEWS.CHOOSE_SHADE)}>
              Create your shade
            </Button>
          </Flex>
          <Button
            size='lg'
            backgroundColor={'whiteAlpha.600'}
            color={'white'}
            variant={'outline'}
            borderWidth={3}
            onClick={props.goToView.bind(null, VIEWS.USE_COLOR_CODE)}
          >
            Use a color code
          </Button>
        </GridItem>
      </Grid>
      <Box
        position='absolute'
        top={0}
        bottom={0}
        left={0}
        right={0}
        overflow={'hidden'}
        display={'flex'}
        bgColor={'black'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <video autoPlay loop muted style={{ width: '100%' }}>
          <source src={video} type='video/mp4' />
        </video>
      </Box>
    </LayoutWrapper>
  );
}
