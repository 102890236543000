import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  Image,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import logo from 'assets/ellure-logo.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '.';

export default function Signin() {
  const { signin, error } = useAuth();
  const form = useFormik({
    initialValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().email('Invalid username').required('Username is required'),
      password: Yup.string().min(4, 'Invalid password').required('Password is required'),
      rememberMe: Yup.bool().isFalse(),
    }),
    onSubmit: async (values, helpers) => {
      helpers.setStatus(undefined);

      try {
        await signin(values.username, values.password);
      } catch (e) {
        if (e instanceof Error) {
          helpers.setStatus({
            message: e.message,
          });
        }
      }
    },
  });

  return (
    <Box alignSelf='center' p={6} bg='white' w='100%' maxWidth='800px' borderColor='gray.200' borderWidth='1px'>
      <Flex flexDirection='column' alignItems='center' mx='14' pt='6' pb='2' justifyContent='space-between'>
        <Image src={logo} alt='Ellure Cloud Factory' width='100px' />
        <Text fontSize='sm'>Cloud Factory</Text>
        <form onSubmit={form.handleSubmit}>
          <Stack mt={6}>
            <FormControl isInvalid={!!form.errors.username}>
              <Input
                placeholder='Your@email.com'
                id='username'
                name='username'
                type='email'
                variant='filled'
                onChange={form.handleChange}
                value={form.values.username}
              />
            </FormControl>
            <FormControl isInvalid={!!form.errors.password}>
              <Input
                placeholder='Password'
                id='password'
                name='password'
                type='password'
                variant='filled'
                onChange={form.handleChange}
                value={form.values.password}
              />
            </FormControl>
            <Button type='submit' colorScheme='blue'>
              Sign-in
            </Button>
          </Stack>
        </form>
        {form.status && (
          <Alert status='error' mt={6} alignItems='flex-start' maxW='450px'>
            <AlertIcon />
            <Flex flexDir='column'>
              <AlertTitle flex='max-content'>Authentication failed.</AlertTitle>
              <AlertDescription>{form.status.message}</AlertDescription>
            </Flex>
          </Alert>
        )}
        {error && !form.status && (
          <Alert status='error' mt={6} alignItems='flex-start' maxW='450px'>
            <AlertIcon />
            <Flex flexDir='column'>
              <AlertTitle flex='max-content'>Authentication failed.</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Flex>
          </Alert>
        )}
      </Flex>
    </Box>
  );
}
