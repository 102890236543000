import { useEffect, useRef, useState } from 'react';

export type BoundingRect = {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  left: number;
  right: number;
  bottom: number;
};

export function useBoundingRect<T extends HTMLElement>(): [React.RefObject<T>, BoundingRect] {
  const ref = useRef<T>(null); // Reference to the element
  const [rect, setRect] = useState<BoundingRect>({
    x: 0,
    y: 0,
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
  });

  useEffect(() => {
    if (!ref.current) return;

    const updateRect = () => {
      setTimeout(() => {
        if (!ref.current) return;
        setRect(ref.current.getBoundingClientRect());
        console.log('updateRect', ref.current.getBoundingClientRect());
      }, 100);
    };

    const handleViewportChange = () => {
      updateRect();
    };

    const observer = new ResizeObserver(updateRect);
    observer.observe(ref.current);
    updateRect();

    window.addEventListener('scroll', handleViewportChange, { passive: true });
    window.addEventListener('resize', handleViewportChange);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleViewportChange);
      window.removeEventListener('resize', handleViewportChange);
    };
  }, []);

  return [ref, rect];
}
